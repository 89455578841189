import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { isEmpty, get, noop } from 'lodash'
import {
  login,
  logout,
  checkSession,
  isAuthenticated,
  handleRedirectCallback,
  updateUser,
} from '~/service/user'
import { withAuthContext, Provider } from './context'
export { default as AuthGuard } from './AuthGuard'
export { withAuthContext as withAuth } from './context'
import { readRedirectUrl } from '~/utils/auth'

const shouldHandleRedirectCallback = (hash = '') => hash.includes('access_token=') && hash.includes('state=')

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(undefined)
  const [currentUser, setCurrentUser] = useState(undefined)
  const [expiresAt, setExpiresAt] = useState(undefined)
  const [twilioAccessToken, setTwilioAccessToken] = useState(undefined)
  const isLoggedIn = expiresAt === undefined ? undefined : isAuthenticated(expiresAt)

  const setAttributes = ({ user, accessToken, expiresAt, twilio = {}}) => {
    setCurrentUser(user)
    setAccessToken(accessToken)
    setExpiresAt(expiresAt)
    setTwilioAccessToken(twilio.token)
  }

  async function onLoad() {
    let result

    if (shouldHandleRedirectCallback(window.location.hash)) {
      result = await handleRedirectCallback()
      const next = readRedirectUrl(result.nonce) || '/'
      navigate(next)
    } else {
      result = await checkSession()
    }

    if (result) {
      setAttributes(result)
    }
  }

  const handleLogout = () => {
    logout()
    setAttributes(undefined)
  }

  const handleUpdateUser = async (attrs = {}) => {
    const updatedUser = {
      ...currentUser,
      ...attrs,
    }
    await updateUser(accessToken, updatedUser)
    setCurrentUser(updatedUser)
  }

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <Provider value={{
      isLoggedIn,
      user: currentUser,
      updateUser: isLoggedIn ? handleUpdateUser : noop,
      login,
      logout: handleLogout,
      accessToken,
      twilioAccessToken,
    }}>
      { children }
    </Provider>
  )
}
