export const promisify = fn =>
  new Promise((resolve, reject) =>
    fn((err, data) => err ? reject(err) : resolve(data))
  )

export const debounce = (fn, delay = 16) => {
  let scheduled
  return async (...args) =>
    new Promise((resolve, reject) => {
      clearTimeout(scheduled)
      scheduled = setTimeout(async () => {
        try {
          const result = await fn(...args)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      }, delay)
    })
}