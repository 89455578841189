import React, { useContext } from 'react'

export const makeWithContext = Context =>
  WrappedComponent =>
    props => (
      <WrappedComponent
        {...props}
        {...useContext(Context)}
      />
    )
