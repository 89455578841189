import React from 'react'
import { LocaleProvider } from './components/common/Locale'
import NewMessagesProvider from './components/common/NewMessages'
import { AuthProvider } from './components/common/Auth'
import { defaultLocale } from './i18n'
import './assets/styles/global.scss'

export default ({ element }) =>
  <AuthProvider>
    <LocaleProvider>
      <NewMessagesProvider>
        { element }
      </NewMessagesProvider>
    </LocaleProvider>
  </AuthProvider>
