import { camelCase } from 'lodash'
import settings from './settings'

const handleError = response => {
  throw response
}

const handleSuccess = async response => {
  const text = await response.text()
  return text && JSON.parse(text)
}

async function callApi(method = 'get', endpoint, payload, headers = {}) {
  const response = await fetch(endpoint, {
    method,
    mode: 'cors',
    ...(payload ? {
      body:
        headers['Content-Type'] === 'application/x-www-form-urlencoded' ?
          payload :
          JSON.stringify(payload)
    } : {}),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  })

  return response.ok ?
    await handleSuccess(response) :
    handleError(response)
}

async function callApiAuthorized(method, endpoint, payload, headers = {}, token) {
  return callApi(method, endpoint, payload, {
    ...headers,
    'Authorization': `Bearer ${ token }`,
  })
}

const methods = ['get', 'post', 'put', 'delete']

const simple = methods.reduce((accum, method) => {
  accum[method] = callApi.bind(null, method)
  return accum
}, {})

const authorized = methods.reduce((accum, method) => {
  accum[camelCase(`authorized-${ method }`)] = callApiAuthorized.bind(null, method)
  return accum
}, {})

export default {
  ...simple,
  ...authorized,
}