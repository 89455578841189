import React from 'react'
import cx from 'classnames'
import style from './loading.module.scss'

export default ({ isFullScreen }) => (
  <div className={ cx(style.main, {
    [style.fullScreen]: isFullScreen,
  }) }>
    <svg className={ style.circle } viewBox="25 25 50 50">
      <circle className={ style.path } cx="50" cy="50" r="20" fill="none" strokeWidth="4" />
    </svg>
  </div>
)