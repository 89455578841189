// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-visibility-group-js": () => import("/opt/build/repo/src/templates/visibilityGroup.js" /* webpackChunkName: "component---src-templates-visibility-group-js" */),
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-institutional-article-js": () => import("/opt/build/repo/src/templates/institutionalArticle.js" /* webpackChunkName: "component---src-templates-institutional-article-js" */),
  "component---src-pages-chat-js": () => import("/opt/build/repo/src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-callback-js": () => import("/opt/build/repo/src/pages/login/callback.js" /* webpackChunkName: "component---src-pages-login-callback-js" */),
  "component---src-pages-login-index-js": () => import("/opt/build/repo/src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-profile-js": () => import("/opt/build/repo/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("/opt/build/repo/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-validation-required-js": () => import("/opt/build/repo/src/pages/validation/required.js" /* webpackChunkName: "component---src-pages-validation-required-js" */),
  "component---src-pages-validation-successful-js": () => import("/opt/build/repo/src/pages/validation/successful.js" /* webpackChunkName: "component---src-pages-validation-successful-js" */)
}

