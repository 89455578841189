import React, { useEffect } from 'react'
import { get } from 'lodash'
import { navigate } from 'gatsby'
import routes from '~/routes'
import Loading from 'components/common/Loading'
import { withAuthContext } from './context'

export default withAuthContext(({ user = {}, logout, isLoggedIn, children, location }) => {
  useEffect(() => {
    if (isLoggedIn === false && typeof window !== 'undefined') {
      navigate(routes.login(location.pathname))
    } else if (isLoggedIn === true && !get(user, 'isValidated')) {
      navigate(routes.validationRequired())
    }
  }, [isLoggedIn, user.isValidated])

  return isLoggedIn === undefined ?
    <Loading isFullScreen /> :
    children
})