import { find, first, mergeWith, size } from 'lodash'
import { defaultNull } from './misc'

// @todo, move to a selector

export const findNode = (nodes, fn) => find(nodes, fn)

export const findLocalizedNode = (nodes, locale) => {
  const node = findNode(nodes, ({ node_locale }) => node_locale === locale)
  return node || first(nodes)
}

export const findLocalizedNodeWithFallback = (nodes, locale, fallbackLocale) => {
  const localized = findLocalizedNode(nodes, locale)
  const fallback = findLocalizedNode(nodes, fallbackLocale)
  return defaultNull(localized, fallback)
}

export const filterLocalizedNodes = (nodes, locale) =>
  nodes.filter(({ node_locale }) => node_locale === locale)

