import React, { useState, useEffect } from 'react'
import { withAuth } from 'components/common/Auth'
import Loading from 'components/common/Loading'
import { getClient } from '~/service/chat'

const Client = withAuth(({ render, user = {}, twilioAccessToken }) => {
  const [client, setClient] = useState(undefined)

  const init = async () => {
    if (twilioAccessToken) {
      const client = await getClient(twilioAccessToken)
      setClient(client)
    }
  }

  useEffect(() => {
    init()
  }, [user.twilioId, twilioAccessToken])

  return render(client, user.twilioId)
})

export const withTwilioClient = (Wrapped, { showLoading = true } = {}) =>
  props =>
    <Client
      render={
        (client, twilioId) => client ?
          <Wrapped { ...props } client={ client } twilioId={ twilioId } /> :
          showLoading ?
            <Loading isFullScreen /> :
            props.children
      }
    />

export default Client
