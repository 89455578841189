import { WebAuth, Management } from 'auth0-js'
import { get, omit, pick, flowRight } from 'lodash'
import settings from '~/settings'
import { outboundProfile, inboundProfile, inboundProfileDescriptor } from './transforms'
import api, { authorizedPost } from '~/api'
import { promisify } from '../../utils/async'
import { stringify } from 'query-string'

const defaultScope = [
  'openid',
  'profile',
  'email',
  'roles',
  'read:current_user',
  'update:current_user_identities',
  'update:current_user_metadata',
  'delete:current_user_metadata',
].join(' ')

const client = new WebAuth({
  domain: settings.auth0.domain,
  audience: `https://${ settings.auth0.domain }/api/v2/`,
  clientID: settings.auth0.client_id,
  redirectUri: settings.auth0.redirect_uri,
  responseType: 'id_token token',
  scope: defaultScope,
})

const handleAuth = (resolve, reject) => (err, result) => {
  if (err) return reject(err)
  if (!result || !result.idToken) {
    return reject(err)
  }

  const {accessToken, idTokenPayload = {}} = result

  getTwilioUser(accessToken).then(twilio =>
    resolve({
      twilio,
      user: inboundProfile(idTokenPayload),
      accessToken: accessToken,
      expiresAt: (idTokenPayload.exp || 0) * 1000,
      nonce: idTokenPayload.nonce,
    })
  )
}

export function handleRedirectCallback() {
  return new Promise((resolve, reject) => {
    try {
      client.parseHash({ hash: window.location.hash }, handleAuth(resolve, reject))
    } catch(e) {
      console.log(e)
    }
  })
}

export const login = options => client.authorize(options)

export const logout = options => client.logout(options)

export const isAuthenticated = expiresAt => expiresAt > new Date().getTime()

export function checkSession() {
  return new Promise(resolve => {
    client.checkSession({}, handleAuth(resolve, () => resolve({
      accessToken: null,
      user: undefined,
      expiresAt: 0,
    })))
  })
}

export async function register(user) {
  return api.post(`${ settings.external.api }/signup`, outboundProfile(user))
}

export async function updateUser(token, user) {
  return api.authorizedPost(`${ settings.external.api }/update`, outboundProfile(user), {}, token)
}

export async function getTwilioUser(token) {
  return api.authorizedGet(`${ settings.external.api }/token`, undefined, {}, token)
}

export async function searchUser(token, term) {
  try {
    const users = await api.authorizedGet(
      `${ settings.external.api }/findpeers?q=${ encodeURIComponent(term) }`,
      undefined, {}, token
    )
    return users.map(inboundProfileDescriptor)
  } catch (e) {
    console.log(e)
    return []
  }
}

export async function getSuggestedUsers(token) {
  try {
    const users = await api.authorizedGet(
      `${ settings.external.api }/suggestpeers`,
      undefined, {}, token
    )
    return users.map(inboundProfileDescriptor)
  } catch (e) {
    console.log(e)
    return []
  }
}
