const INJURED = 'injured'
const RELATIVE = 'relative'
const PROFESSIONAL = 'professional'

module.exports = {
  userTypes: [
    INJURED,
    RELATIVE,
    PROFESSIONAL,
  ],
  roleNamesByUserType: {
    [INJURED]: INJURED,
    [RELATIVE]: RELATIVE,
    [PROFESSIONAL]: PROFESSIONAL,
  },
  visibilities: [
    'public',
    'private',
    'professional'
  ],
  availableLocales: {
    'es-ES': 'Español',
    'ca-ES': 'Catalá',
  },
  userFields: {
    general: {
      standard: ['email', 'password', 'picture', 'given_name'],
      nonStandard: ['birthday', 'gender', 'postcode', 'phone', 'type', 'twilioId'],
    },
    specific: {
      [INJURED]: ['mobility', 'injuryLevel', 'injuryType', 'injuryYear', 'mobilityOptions'],
      [RELATIVE]: ['injuryLevel', 'relationship'],
      [PROFESSIONAL]: ['extension', 'workCountry', 'workCity', 'registrationNumber', 'profession'],
    },
  },
  errors: {
    duplicateResource: 'Duplicate Resource',
  },
}
