import { reduce, times, truncate, padStart } from 'lodash'
export const findIndex = (str, needle) => {
  const position = str.indexOf(needle)
  return position !== -1 ? position : 0
}

export const truncateWithCenter = (str = '', center = 0, length = 20) => {
  const idealStart = Math.floor(center - length / 2)
  const correctedStart = Math.min(idealStart, str.length - length)
  const safeStart = Math.max(0, correctedStart)
  const fromStart = str.substring(safeStart)
  const withLeadingEllipsis = safeStart > 0 ? `...${ fromStart }` : fromStart
  return truncate(withLeadingEllipsis, { length })
}

export const randomString = (length = 1) => {
  const repo = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  return reduce(times(length), accum => {
    accum += repo.charAt(Math.floor(Math.random() * repo.length))
    return accum
  }, '')
}

export const nn = n => padStart(n, 2, '0')