import {
  filter, find, head, keys, last, map, isObject,
  isArray, merge, reduce, sortBy, times, size,
  upperCase,
} from 'lodash'

import qs from 'query-string'

export const yearOptions = yearsFromNow => {
  const now = (new Date()).getFullYear()
  return times(90, y => [String(now - y), String(now - y)])
}

const extractor = fn => items => map(items, fn)

export const heads = extractor(head)
export const tails = extractor(last)

export const tailByHead = (items, head) => {
  const item = find(items, ([value, label]) => value === head)
  return item ? item[1] : undefined
}

export const firstKey = (obj) =>
  size(obj) ? obj[head(keys(obj))] : undefined

export const putFirst = (items, matcher) =>
  sortBy(items, item => matcher(item) ? 0 : 1)

export const compact = arr => filter(arr, item =>
  isObject(item) ? size(item) > 0 : Boolean(item)
)

export const getLocationParam = (location, name) => {
  const params = qs.parse(location.search)
  return params[name]
}

const nullAsUndefined = value => value === null ? undefined : value

const unsetNull = obj =>
  reduce(obj, (accum, value, key) => {
    accum[key] = isObject(value) ? unsetNull(value) : nullAsUndefined(value)
    return accum
  }, isArray(obj) ? [] : {})

export const defaultNull = (obj, fallback) =>
  merge({ ...fallback }, unsetNull(obj))

export const selectNode = ({ node }) => node

export const selectNodes = edges => map(edges, selectNode)

export const initials = (firstName = ' ', lastName = ' ') =>
  upperCase(`${ firstName[0] }${ lastName[0] }`)

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export const globalConfigurationNodesAsMap = edges =>
  reduce(edges, (accum, { node }) =>
    ({
      ...accum,
      [node.key]: node.value,
    }),
  {})
