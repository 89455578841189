import { randomString } from './string'

export const storeReturnUrl = url => {
  const nonce = randomString(16)
  window.localStorage.setItem(nonce, url)
  return nonce
}

export const readRedirectUrl = nonce =>
  window.localStorage.getItem(nonce)
