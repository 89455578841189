import React from 'react'
import cx from 'classnames'
import { withContext } from './context'
import style from './indicator.module.scss'

export default withContext(({ className, children, hasNewMessages, visible = true }) =>
  <span className={ style.main }>
    {
      visible && hasNewMessages ?
        <span className={ cx(style.indicator, className) } /> :
        null
    }
    { children }
  </span>
)