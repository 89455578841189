import { Client } from 'twilio-chat/lib/index'
import { t } from '@lingui/macro'
import Video from 'twilio-video'
import { compact, curry, get, find, includes, last, map, memoize, noop, pick, size, uniqBy, uniqueId } from 'lodash'

const key = 'h*2xusa8&la0'
const requestVideoMessage = `JoinVideo_${ key }`
const acceptVideoMessage = `AcceptVideo_${ key }`
const rejectVideoMessage = `RejectVideo_${ key }`
const endVideoMessage = `EndVideo_${ key }`

export async function getClient(accessToken) {
  return await Client.create(accessToken/* , { logLevel: 'debug' } */)
}

export async function autoJoinChannelWhenInvited(channel) {
  const { status } = channel
  return (status === 'known' || status === 'invited') ?
    await channel.join() :
    Promise.resolve()
}

const peerFromMessageAttrs = message => ({
  twilioId: message.author,
  ...(message.attributes || {})
})

const handleInteractiveMessage = curry((pattern, peer1, handlerFn, message) =>
  message.author !== peer1.twilioId && includes(message.body, pattern) ?
    handlerFn(message, peerFromMessageAttrs(message)) : noop()
)

export const listenVideoRequest = handleInteractiveMessage(requestVideoMessage)
export const listenVideoAccept = handleInteractiveMessage(acceptVideoMessage)
export const listenVideoReject = handleInteractiveMessage(rejectVideoMessage)
export const listenVideoEnd = handleInteractiveMessage(endVideoMessage)

export const formatInteractiveMessage = memoize(i18n => {
  const formatted = {
    [requestVideoMessage]: i18n._(t`Videollamada enviada`),
    [acceptVideoMessage]: i18n._(t`Videollamada aceptada`),
    [rejectVideoMessage]: i18n._(t`Videollamada rechazada`),
    [endVideoMessage]: i18n._(t`Videollamada finalizada`),
  }
  return message => formatted[message] || message
})

const getUniqueName = (prefix, id1, id2) => `${ prefix }_${ [id1, id2].sort().join('-') }`
const getRoomUniqueName = (peer1, peer2) => getUniqueName('Room', peer1.twilioId, peer2.twilioId)
const getChannelUniqueName = (peer1, peer2) => getUniqueName('Channel', peer1.twilioId, peer2.twilioId)
const getChannelFriendlyName = (peer1, peer2) => `${ peer1.firstName } - ${ peer2.firstName }`

const findChannel = async (client, user, peer) => {
  const channels = await client.getSubscribedChannels()
  return find(channels.items, ({ uniqueName }) =>
    uniqueName.includes(user.twilioId) &&
    uniqueName.includes(peer.twilioId)
  )
}

export const asPeer = user => pick(user, ['picture', 'firstName', 'lastName', 'centers', 'twilioId'])

const createChannel = async (client, user, peer) => {
  const friendlyName = getChannelFriendlyName(user, peer)
  const uniqueName = getChannelUniqueName(user, peer)
  const channel = await client.createChannel({
    attributes: { conversation: { peer1: asPeer(user), peer2: asPeer(peer) }},
    friendlyName,
    uniqueName,
    isPrivate: true,
  })

  await channel.join()
  await channel.invite(peer.twilioId)
  return channel
}

export async function getChannel(client, user, peer) {
  const existing = await findChannel(client, user, peer)
  return await (
    existing ?
      Promise.resolve(existing) :
      createChannel(client, user, peer)
  )
}

export async function getLastConsumedMessageIndex(channel, user) {
  const members = await channel.getMembers()
  return (members.find(({ identity }) => user.twilioId) || {}).lastConsumedMessageIndex || 0
}

const getPeerFromConversation = (user, { peer1 = {}, peer2 = {}}) =>
  peer1.twilioId === user.twilioId ? peer2 :
  peer2.twilioId === user.twilioId ? peer1 :
  {}

export async function getConversations(client, user = {}) {
  const {items} = await client.getSubscribedChannels()
  return Promise.all(map(items, async channel => {
    const unconsumedMessages = await channel.getUnconsumedMessagesCount()
    return {
      ...getPeerFromConversation(user, get(channel, 'attributes.conversation', {})),
      dateUpdated: new Date(get(channel, 'lastMessage.timestamp') || channel.dateUpdated),
      hasUnconsumedMessages: unconsumedMessages > 0,
    }
  }))
}

async function getMessagesRecursive(accum, { nextPage, hasNextPage, items }, limit = 1000) {
  accum = [...accum, ...items]
  const next = await (hasNextPage ? nextPage() : Promise.resolve(false))
  return await (next && size(accum) < limit ? getMessagesRecursive(accum, next, limit) : Promise.resolve(accum))
}

export async function getAllMessages(channel) {
  const page = await channel.getMessages(30, 0, 'forward')
  const messages = await getMessagesRecursive([], page)
  return messages
}

export async function connectVideo(accessToken, peer1, peer2) {
  const name = getRoomUniqueName(peer1, peer2)
  try {
    const localTracks = await Video.createLocalTracks({ audio: true, video: { width: 640 }})
    return await Video.connect(accessToken, {
      name,
      tracks: localTracks,
      preferredVideoCodecs: ['H264'],
      video: { width: 640 },
    })
  } catch (e) {
    console.log('Could not connect to Twilio: ' + e.message)
    console.error(e)
  }
}

const sendMessageToPeer = message =>
  async (client, user, peer) => {
    const channel = await getChannel(client, user, peer)
    return await channel.sendMessage(message, {
      ...asPeer(user),
      to: peer.twilioId,
    })
  }

export const requestVideo = sendMessageToPeer(requestVideoMessage)
export const acceptVideo = sendMessageToPeer(acceptVideoMessage)
export const rejectVideo = sendMessageToPeer(rejectVideoMessage)
export const endVideo = sendMessageToPeer(endVideoMessage)

