import { setupI18n } from '@lingui/core'
import { head, keys, forEach } from 'lodash'
import es from '@lingui/loader!./locale/es/messages.po'
import ca from '@lingui/loader!./locale/ca/messages.po'
import { availableLocales } from '~/constants'
export { availableLocales } from '~/constants'
export const defaultLocale = head(keys(availableLocales))

const i18n = setupI18n()
i18n.load({ 'es-ES': es, 'ca-ES': ca })

export default i18n
