import React, { createContext, useState } from 'react'
import { flowRight, head, keys } from 'lodash'
import { I18nProvider } from '@lingui/react'
import i18n, { availableLocales, defaultLocale } from '~/i18n'
import { makeWithContext } from '~/utils/context'
import { findLocalizedNodeWithFallback } from '~/utils/content'
import { selectNodes } from '~/utils/misc'

const Context = createContext()

export const withLocale = makeWithContext(Context)

export const LocaleProvider = ({ children }) => {
  let preferredLocale = ''
  if (typeof window !== 'undefined') {
    preferredLocale = window.localStorage.getItem('preferredLanguage')
  }
  const [locale, setLocale] = useState(preferredLocale || defaultLocale)

  const handleSetLocale = locale => {
    window.localStorage.setItem('preferredLanguage', locale)
    setLocale(locale)
  }

  return (
    <Context.Provider value={{
      locale,
      availableLocales,
      i18n,
      setLocale: handleSetLocale,
    }}>
      <I18nProvider i18n={ i18n.use(locale) } language={ locale }>
      { children }
      </I18nProvider>
    </Context.Provider>
  )
}

const selectFirstEdges = data => data[head(keys(data))].edges

export const withLocalizedQueryData = flowRight(
  withLocale,
  Wrapped =>
    ({ data, ...props }) =>
      <Wrapped
        data={
          findLocalizedNodeWithFallback(
            selectNodes(selectFirstEdges(data)),
            props.locale,
            defaultLocale
          )
        }
        { ...props }
      />
)