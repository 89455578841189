import { camelCase, get, identity, isEmpty, isArray, map, mapValues, noop, pick, trim, uniq } from 'lodash'
import { userFields } from '~/constants'

const { general, specific } = userFields
const appNamespace = 'https://lemon-sci.org/'

const getMetadata = (user = {}) => [
  get(user, `${ appNamespace }user_metadata`, {}),
  get(user, `${ appNamespace }app_metadata`, {}),
]

const picker = fields => data => pick(data, fields)

const standardFields = picker(general.standard)
const nonStandardFields = picker(general.nonStandard)

const specificFields = specific
const specificFieldsByType = {
  injured: picker(specificFields.injured),
  relative: picker(specificFields.relative),
  professional: picker(specificFields.professional),
}

const serializableFieldsByType = {
  injured: ['mobilityOptions'],
  relative: [],
  professional: [],
}

const isValidated = user => {
  const [userMetadata, appMetadata] = getMetadata(user)
  return userMetadata.type === 'professional' ? appMetadata.validated : true
}

export const inboundProfile = user => {
  const [userMetadata] = getMetadata(user)
  return !isEmpty(user) ? ({
    ...standardFields(user),
    id: user.sub,
    firstName: user.name,
    lastName: user.family_name,
    centers: JSON.parse(user.given_name),
    ...nonStandardFields(userMetadata),
    ...specificFieldsByType[userMetadata.type](userMetadata),
    ...mapValues(picker(serializableFieldsByType[userMetadata.type])(userMetadata), JSON.parse),
    isValidated: isValidated(user),
  }) : {}
}

export const outboundProfile = (data = {}) => ({
  ...standardFields(data),
  name: data.firstName,
  family_name: data.lastName,
  given_name: JSON.stringify(data.centers),
  user_metadata: {
    ...nonStandardFields(data),
    ...specificFieldsByType[data.type](data),
    ...mapValues(picker(serializableFieldsByType[data.type])(data), JSON.stringify),
  },
})

export const inboundProfileDescriptor = (data = {}) => ({
  firstName: data.name,
  lastName: data.family_name,
  centers: JSON.parse(data.given_name),
  twilioId: data.twilioId,
  picture: data.picture,
  type: data.type,
})
