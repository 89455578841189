module.exports = {
  article: article => `/${ article.node_locale }/article/${ article.slug }/`,
  institutional: article => `/${ article.node_locale }/institutional/${ article.key }`,
  visibilityGroup: visibilityGroup => `/${ visibilityGroup.node_locale }/${ visibilityGroup.slug }`,
  category: category => `/${ category.node_locale }/category/${ category.slug }/`,
  chat: () => '/chat/',
  home: () => '/',
  login: returnUrl => returnUrl ? `/login?returnUrl=${ returnUrl }` : '/login',
  profile: () => '/profile',
  register: type => type ? `/register?type=${ type }` : '/register',
  search: (q) => `/search?q=${ q }`,
  validationRequired: () => '/validation/required',
}
