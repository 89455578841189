export default {
  'external': {
    'api': process.env.GATSBY_EXTERNAL_API_URL, //'https://api.lemon-sci.org',
    'chat': process.env.GATSBY_EXTERNAL_CHAT_URL, // 'https://api.lemon-sci.org/chat',
    //'https://lemon-frontend-services.netlify.com/.netlify/functions/server/feedback'
  },
  'contentful': {
    'spaceId': process.env.GATSBY_CONTENTFUL_SPACE_ID, // '8xvsuumc558u',
    'accessToken': process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN, //'c56c29a40434ed94606c16dbe11aeaa219e24edd3f22f99fa4e37a3dcde8bf42'
  },
  's3': {
    'accessKey': process.env.GATSBY_S3_ACCESS_KEY, // 'AKIAJ6SM5V577ER7FNAA',
    'secretAccessKey': process.env.GATSBY_S3_SECRET_ACCESS_KEY, //'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',
    'bucketName': process.env.GATSBY_S3_BUCKET_NAME,  // 'lemon-files',
    'bucketRegion': process.env.GATSBY_S3_BUCKET_REGION, //'eu-central-1'
  },
  'algolia': {
    'appId': process.env.GATSBY_ALGOLIA_APP_ID, // 'FE6WGWWIA4',
    'apiKey': process.env.GATSBY_ALGOLIA_API_KEY, // 'c5da2ecd8191e2077b65b241e1cac70d',
    'index': process.env.GATSBY_ALGOLIA_INDEX, // 'LemonArticles'
  },
  'intercom': {
    'appId': process.env.GATSBY_INTERCOM_APP_ID, //'csvpsh3r'
  },
  'google': {
    'analyticsId': process.env.GATSBY_GOOGLE_ANALYTICS_ID, //'UA-119554386-1'
  },
  'auth0': {
    'domain': process.env.GATSBY_AUTH0_DOMAIN,
    'client_id': process.env.GATSBY_AUTH0_CLIENT_ID,
    'redirect_uri': process.env.GATSBY_AUTH0_REDIRECT_URI,
    'dbconnection': process.env.GATSBY_AUTH0_DBCONNECTION,
  },
}
