import React, { useEffect, useState } from 'react'
import routes from '~/routes'
import { Location } from '@reach/router'
import { withTwilioClient } from 'components/common/TwilioClient'
import { withContext, Provider } from './context'
export { withContext } from './context'
export { default as Indicator } from './Indicator'

const NewMessagesProvider = withTwilioClient(({ location, children, client, twilioId }) => {
  const [hasNewMessages, setHasNewMessages] = useState(false)
  const handleMessageAdded = message => {
    if (message.author !== twilioId) {
      setHasNewMessages(true)
    }
  }

  useEffect(() => {
    client.on('messageAdded', handleMessageAdded)
    return () => client.removeListener('messageAdded', handleMessageAdded)
  }, [])

  useEffect(() => {
    if (location.pathname === routes.chat()) {
      setHasNewMessages(false)
    }
  }, [location.pathname])

  return (
    <Provider value={{ hasNewMessages }}>
      { children }
    </Provider>
  )
}, { showLoading: false })

export default props =>
  <Location>{
    ({ location }) =>
      <NewMessagesProvider location={ location } { ...props } />
    }
  </Location>